import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";


import { flavor } from "../../config/Flavor";
import { validateName, validateEmail, validatePassword } from "../../helpers/Validators";
import { fireTikTokEvent } from '../../helpers/TikTokPixel';
import classes from '../Login/Login.module.scss';
import GenderCheckbox from '../UI/GenderCheckbox/GenderCheckbox';
import FacebookLogin from "../UI/FacebookLogin/FacebookLogin";
import Select from '../UI/Select/Select';
import Loader from "../UI/Loader/Loader";
import axios from "../../config/Axios";
import { loginUser } from '../../store/auth';
import { modalActions } from '../../store/modal';
import GoogleLoginButton from "../GoogleLoginButton";

const ageList = [{label: 'Choose age', value: '', disabled: true}];
for (let i = 18; i <= 99; i++) {
    ageList.push({label: i, value: i});
}

const Registration = () => {
    const dispatch = useDispatch();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [fields, setFields] = useState({
        sex: null,
        name: '',
        email: '',
        password: '',
        birthday: ''
    });
    const [fieldErrors, setFieldErrors] = useState({
        sex: null,
        name: null,
        email: null,
        password: null,
        birthday: null,
    });
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [successSubmit, setSuccessSubmit] = useState(false);
    const [emailResend, setEmailResend] = useState(false);

    useEffect(() => {fireTikTokEvent('OpenRegistration')}, []);

    const updateField = (e) => {
        let fieldsUpdated = {...fields};
        fieldsUpdated[e.target.name] = e.target.value;
        setFields(fieldsUpdated);
    }

    const updateGender = gender => {
        const fieldsUpdated = {...fields};
        fieldsUpdated['sex'] = gender;
        setFields(fieldsUpdated);
    }

    const onLogin = () => {
        dispatch(modalActions.close());
    }

    const initApiCallToRegistration = async (data) => {
        data['googleRecaptchaToken'] = executeRecaptcha ? await executeRecaptcha('tg_react_login') : null;

        axios.post('auth/register', data)
            .then(r => {
                setIsLoading(false);
                setError(null);
                setSuccessSubmit(true);
                if (r.data.eventStatus === 'login') {
                    dispatch(loginUser(r.data.user.id, onLogin));
                }
            }).catch(error => {
            const errorMsg = (error.response.data.error === 'used-email') ? 'Email exists' : error.response.data.error;
            setIsLoading(false);
            setError(errorMsg);
        });
    }

    const initRegister = (e) => {
        e.preventDefault();

        if (isLoading) {
            return;
        }

        const errorList = {...fieldErrors};
        errorList.sex = !(fields.sex);
        errorList.name = validateName(fields.name);
        errorList.email = !(validateEmail(fields.email));
        errorList.password = !(validatePassword(fields.password));
        errorList.birthday = !(fields.birthday);
        setFieldErrors(errorList);

        if (errorList.sex || errorList.name || errorList.email || errorList.password || errorList.birthday) {
            return;
        }
        else {
            setIsLoading(true);
            initApiCallToRegistration({...fields});
        }
    }

    const _checkName = () => {
        const errorList = {...fieldErrors};
        errorList.name = validateName(fields.name);
        setFieldErrors(errorList);
    }

    const _checkEmail = () => {
        const errorList = {...fieldErrors};
        errorList.email = !(validateEmail(fields.email));
        setFieldErrors(errorList);
    }

    const _checkPassword = () => {
        const errorList = {...fieldErrors};
        errorList.password = !(validatePassword(fields.password));
        setFieldErrors(errorList);
    }

    const resendEmail = () => {
        setEmailResend(true);
        axios.post('auth/resend_email', {email: fields.email});
    }

    // const _checkAge = () => {
    //     const errorList = {...fieldErrors};
    //     errorList.age = !(fields.age);
    //     setFieldErrors(errorList);
    // }

    const genderClassList = [classes.GenderOptions];
    if (fieldErrors.sex) {
        genderClassList.push(classes.InputError);
    }

    let contentHtml = (
        <div className={classes.AreaJoinUs}>
            <div className={classes.OptionsScreen}>
                <div className={classes.Title}>Join Us</div>
                <GoogleLoginButton />
                <FacebookLogin />
                <div className={classes.Or}>or</div>
                <form className={classes.LoginForm} onSubmit={initRegister}>
                    <div className={classes.RegistrationText}>Fill this form to become a member</div>

                    <div className={genderClassList.join(' ')}>
                        <div className={classes.Iam}>I am:</div>
                        <div className={classes.Spacer} />
                        <GenderCheckbox text={flavor.genderText.male} checked={(fields.sex === 'male')} setAction={() =>updateGender('male')} />
                        <div className={classes.Spacer} />
                        <GenderCheckbox text={flavor.genderText.female} checked={(fields.sex === 'female')} setAction={() =>updateGender('female')} />
                    </div>

                    <div className={[classes.InputLabel].join(' ')}>
                        Name
                        {fieldErrors.name ? <div className={classes.InputError}>{fieldErrors.name}</div> : null}
                    </div>
                    <input type="text" name="name" placeholder="Name" value={fields.name} onChange={(e) => updateField(e)} onBlur={_checkName} />

                    <div className={[classes.InputLabel].join(' ')}>
                        E-mail
                        {fieldErrors.email ? <div className={classes.InputError}>Incorrect email address!</div> : null}
                    </div>
                    <input type="email" name="email" placeholder="Email address" value={fields.email} onChange={(e) => updateField(e)} onBlur={_checkEmail} />

                    <div className={[classes.InputLabel].join(' ')}>
                        Password
                        {fieldErrors.password ? <div className={classes.InputError}>Password is too short!</div> : null}
                    </div>
                    <input type="password" name="password" placeholder="Password" value={fields.password} onChange={(e) => updateField(e)} onBlur={_checkPassword} />

                    <div className={[classes.InputLabel].join(' ')}>
                        Age
                        {fieldErrors.birthday ? <div className={classes.InputError}>Choose your age!</div> : null}
                    </div>
                    <Select name={'birthday'} optionSelected={fields.birthday} list={ageList} cssOverride={'SelectRegistration'} onChange={updateField} />
                    <div className={classes.SpacerVertical}></div>

                    {error ? <div className={classes.SubmitError}>{error}</div> : null}
                    <button className={[classes.AuthButton, classes.LoginAction].join(' ')} type="submit">
                        {isLoading ? <Loader white={true} /> : `Join ${flavor.name}`}
                    </button>
                    <div className={classes.TC}>By clicking the “Join” below, I agree to the {flavor.domainName} Terms and conditions and Privacy Policy.</div>
                </form>
            </div>
        </div>
    );

    if (successSubmit) {
        contentHtml = (
            <div className={classes.OptionsScreen}>
                <h2 className={classes.Title}>Please check your email!</h2>
                <p className="CenteredText">An activation email has been sent to: {fields.email}</p>
                <div className={classes.TC}>
                    Note! If you experience any delay in receiving your activation email, please check your spam or junk mail folder.
                    {!emailResend &&<span onClick={resendEmail} className={classes.ResendLink}>Resend email</span>}
                    {emailResend &&<div> Email was sent again. If you still didn't receive your activation email, please try to register with another email.</div>}
                </div>
            </div>
        );
    }

    return contentHtml;
}

export default Registration;
